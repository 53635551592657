import Plotly from 'react-plotly.js'
export type ResponseContext = {
   followup_questions: string[] | null;
 
};
export type AskResponse = {
    answer: string;
    followup_q: ResponseContext;
    citations: Citation[];
    plotly_data: AzureSqlServerCodeExecResult | null
    error?: string;
    message_id?: string;
    feedbackOnMsg?: FeedbackEnum;
    msgStatus: messageStatus;
    date:string;
    exec_results?: ExecResults[];
    isCopilotResponse: string;
};
export type AzureSqlServerCodeExecResult = {
    data: Plotly.Data[]
    layout: Partial<Plotly.Layout>
}
export type ExecResults = {
    intent: string
    search_query: string | null
    search_result: string | null
    code_generated: string | null
}
export type AzureSqlServerExecResult = {
    intent: string
    search_query: string | null
    search_result: string | null
    code_generated: string | null
    code_exec_result?: AzureSqlServerCodeExecResult | undefined
}

export type AzureSqlServerExecResults = {
    all_exec_results: AzureSqlServerExecResult[]
}
export type HideCitations = {
    hideCitations: string;
}

export type ShowMsgFb = {
    showMsgFb: string;
}

export type SpeechAuth = {
    access_token: string;
    region: string;
};

export type Branding = {
    brand_name: string;
    brand_description: string;
    brand_welcome_message_count: number
    brand_welcome_message_line1: string;
    brand_welcome_message_line2: string;
    brand_welcome_message_line3: string;
    brand_welcome_message_line4: string;
    brand_welcome_message_line5: string;
    header_right_image: string;
    header_right_small_image: string;
    header_left_image: string;
    header_left_link: string;
    header_title: string;
    show_heater_title_beta: string;
    updated_on:string;
    show_copyright: string;
    copyright_text: string;
    show_privacy_policy: string;
    privacy_policy_text: string;
    show_user_terms: string;
    user_terms_text: string;
    show_site_translate: string;
    dialog_logo:string;
    dialog_logo_darkmode: string;
    msg_icon:string;
    msg_icon_darkmode:string;
    microphone_text:string;
    caution_text:string;
    add_context:string;
    context:string;
    faqList:faq[];
    placeholder_long_text:string;
    placeholder_abbrev_text:string;
    speech_enabled: string;
    text_to_speech_enabled: string;
    text_to_speech_playbackrate: string;
    show_widget:string;
    widget_url:string;
    pgmList:string[];
    speech_error_msg:string;
    speech_waiting_msg:string;
    speech_enable_confidence_score:string;
    speech_default_language_list:string;
    speech_non_default_language_list: string;
    followup_questions_enabled:string;
    msg_feedback_enabled:string;
    citation_disabled:string;
    feedback_message_positive:string;
    feedback_message_negative:string;
    feedback_message_neutral:string;
    pii_redaction_enabled: string;  
    telephony_api: string;
    telephony_var1: string;
    telephony_var2: string;
}


export type FrontendSettings = {
    auth_enabled?: string | null;
    feedback_enabled?: string | null;
    branding?: Branding;

}
export type pgm = {
    //id:string;
    name:string;
}
export type faq = {
    question: string;
    answer: string;
    requiresAuth: string;
    authType: string;
    authFields: string;
}
export type Citation = {
    part_index?: number;
    content: string;
    id: string;
    title: string | null;
    filepath: string | null;
    url: string | null;
    metadata: string | null;
    chunk_id: string| null;
    reindex_id: string | null;
}

export type ToolMessageContent = {
    citations: Citation[];
    intent: string;
}

export type ChatMessage = {
    id: string;
    role: string;
    content: string;
    context: ResponseContext;
    end_turn?: boolean;
    date: string;
    prompt_tokens: number;
    completion_tokens: number;
    feedbackOnMsg?: FeedbackEnum;
    copilot_convId?: string;
    copilot_watermark?: string;
    isFaq?:string; 
    copilot_endConv?: string;
    clearAuth?: string;
    copilot_tranferAgent?: string
};

export type FeedbackMessage = {
    rating: number;
    feedback:string;
    assistantMessageId?: string
    feedbackMsg?: string,
};


export type Conversation = {
    id: string;
    title: string;
    messages: ChatMessage[];
    date: string;
  
}

export enum ChatCompletionType {
    ChatCompletion = "chat.completion",
    ChatCompletionChunk = "chat.completion.chunk"
}

export type ChatResponseChoice = {
    messages: ChatMessage[];
}

export type ChatResponse = {
    id: string;
    model: string;
    created: number;
    object: ChatCompletionType;
    choices: ChatResponseChoice[];
    history_metadata: {
        conversation_id: string;
        title: string;
        date: string;
    }
    error?: any;
    isFaq?:string;
    isFaqUserMessage?:string;
}

export type ConversationRequest = {
    messages: ChatMessage[];
    chatUUID: string
};
export type FeedbackRequest = {
    message: FeedbackMessage[];
    chatUUID: string
};
export type UserInfo = {
    access_token: string;
    expires_on: string;
    id_token: string;
    provider_name: string;
    user_claims: any[];
    user_id: string;
};
export enum CosmosDBStatus {
    NotConfigured = "CosmosDB is not configured",
    NotWorking = "CosmosDB is not working",
    Working = "CosmosDB is configured and working",
}

export type CosmosDBHealth = {
    cosmosDB: boolean,
    status: string,
    allowMessageDelete: string
}

export enum ChatHistoryLoadingState {
    Loading = "loading",
    Success = "success",
    Fail = "fail",
    NotStarted = "notStarted"
}

export type ErrorMessage = {
    title: string,
    subtitle: string
}

export enum FeedbackEnum {
    Neutral = "neutral",
    Positive = "positive",
    Negative = "negative",
    MissingCitation = "missing_citation",
    WrongCitation = "wrong_citation",
    OutOfScope = "out_of_scope",
    InaccurateOrIrrelevant = "inaccurate_or_irrelevant",
    OtherUnhelpful = "other_unhelpful",
    HateSpeech = "hate_speech",
    Violent = "violent",
    Sexual = "sexual",
    Manipulative = "manipulative",
    OtherHarmful = "other_harmful"
}

export enum messageStatus {
    NotRunning = "Not Running",
    Processing = "Processing",
    Done = "Done"
}

export type ChatAppResponse = {
    choices: ResponseChoice[];
};
export type ResponseChoice = {
    index: number;
    message: string;
    has_toolmsg:string;
};