import React from 'react';

const WorldIcon: React.FC = () => ( 
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.9999 20.982C16.513 20.982 20.9823 16.5128 20.9823 10.9997C20.9823 5.48655 16.513 1.0173 10.9999 1.0173C5.48683 1.0173 1.01758 5.48655 1.01758 10.9997C1.01758 16.5128 5.48683 20.982 10.9999 20.982Z" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11 16.2737C16.5228 16.2737 21 13.9127 21 11.0001C21 8.08763 16.5228 5.72656 11 5.72656C5.47715 5.72656 1 8.08763 1 11.0001C1 13.9127 5.47715 16.2737 11 16.2737Z" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.999 21C13.9563 21 16.3536 16.5228 16.3536 11C16.3536 5.47715 13.9563 1 10.999 1C8.04183 1 5.64453 5.47715 5.64453 11C5.64453 16.5228 8.04183 21 10.999 21Z" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1.01758 11H20.8204" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.918 1.09854V20.9014" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)

export default WorldIcon;